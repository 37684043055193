import { encodeQueryItem } from 'ufo'

export default defineNuxtRouteMiddleware(async (to) => {
  const identity = identityStore()
  const { loggedIn, state } = storeToRefs(identity)

  if (state.value === 'loggingOut') {
    if (isDev)
      console.log('[authenticated] redirecting to /login')
    return await navigateTo('/login')
  }

  // Checking if the user is logged in, redirecting to login if not.
  if (!loggedIn.value) {
    const redirectToQuery
      = to.path !== '/' && to.path !== '/login' ? `?${encodeQueryItem('redirectTo', to.fullPath)}` : ''

    if (isDev) {
      console.log(
        `[authenticated] Redirecting to /login${redirectToQuery ? ` with redirectTo: ${to.fullPath}` : ', no redirectTo'}`,
      )
    }

    return navigateTo(`/login${redirectToQuery}`)
  }

  // Checking if the user has completed the onboarding, redirecting to onboarding if not.
  const { onboardingData } = await useOnboarding()
  if (!onboardingData.value.completed) {
    if (isDev) {
      console.log('[authenticated] redirecting to /onboarding')
    }
    return await navigateTo('/onboarding')
  }
})
